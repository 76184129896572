/* App.css */

/* Globale Stile */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-header {
  background-color: #282c34;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
}

.header-logo {
  height: 70%;
  
}

.logout-btn {
  margin: 1vw;
}

.adduser-btn {
  margin: 1vw;
}

.logout-btn:hover {
  cursor: pointer;
}

.adduser-btn:hover {
  cursor: pointer;
}

.header-login-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2vw;
}

.App-footer {
  background-color: #282c34;
  color: white;
}

/* Hauptinhalt */
main {
  flex: 1;
  
}

h1 {
  margin: 0;
}

/* Login-Komponente */
.Login {
  margin: 20px auto;
  max-width: 400px;
}

.Login input {
  width: 100%;
  padding: 1vh;
  margin-bottom: 10px;
}

.Login button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

/* ReceiptUpload-Komponente */
.ReceiptUpload {
  margin-bottom: 20px;
}

input[type="file"] {
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  height: 80px;
  resize: none;
}

button {
  background-color: #0c32c9;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* ReceiptList-Komponente */
.ReceiptList ul {
  list-style: none;
  padding: 0;
}

.ReceiptList li {
  margin-bottom: 20px;
}

.ReceiptList img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.ReceiptList p {
  margin: 10px 0;
}

/* components/FotoListe.css */
.foto-liste-container {
  text-align: center;
  margin-top: 20px;
}

.foto-liste {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.foto-item {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer; /* Zeigercursor, um anzuzeigen, dass es anklickbar ist */
}

.selected {
  border: 4px solid #e50606;
}

.foto-item.event_selected {
  border: 5px solid #007bff;
  filter: grayscale(100%);
}

.foto-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.foto-date {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 0;
  font-size: 24px;
  text-align: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal img {
  max-width: 90%;
  max-height: 90%;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}

.zoomed {
  cursor: zoom-out;
}

.modal-content img.zoomed {
  cursor: zoom-in;
  transition: transform 0.2s ease-in-out;
}

.zoom-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.zoom-controls button {
  font-size: 20px;
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  background-color: #333;
  color: white;
  cursor: pointer;
}

.user-option-selected {
  background-color: #4caf50;
  color: #e50606;
}

.receiptlist-content {
  
  display: flex;
  flex-direction: column;
}

.receiptlist-content-filter {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.receiptlist-content-filter-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}






.date-picker {
  
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1vh;
}

input[type="date"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
  outline: none;
}

.date-range-separator {
  margin: 0 10px;
  font-weight: bold;
  color: #333;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(30%);
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-clear-button {
  display: none;
}



.user-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.select-box {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
  outline: none;
  width: 200px;
}

.select-box:focus {
  border-color: #007bff;
}

.user-select label {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}


.loading-full-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}